<template>
  <v-form class="expande-horizontal wrap pa-3 centraliza" ref="form">
    <v-flex xs12 md10 class="py-6">
      <div class="expande-horizontal centraliza column wrap">
        <v-img
          width="70"
          src="img/logo_mini.png"
          alt=""
          class="expande-horizontal wrap"
        />
        <span class="pt-3 fonte" style="color: #444;">
          Acesse para gerenciar sua empresa
        </span>
      </div>
    </v-flex>

    <v-flex class="ma-1 mb-0" xs12 md6>
      <v-text-field
        label="E-mail"
        placeholder="ex: email@gmail.com"
        filled
        rounded
        dense
        :color="$theme.primary"
        prepend-inner-icon="mdi-account-circle-outline"
        class="fonte-bold"
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.username"
        autocomplete="new-login"
      >
      </v-text-field>
    </v-flex>

    <v-flex class="ma-1 mt-0" xs12 md6>
      <v-text-field
        label="Senha"
        placeholder="Digite sua senha"
        :color="$theme.primary"
        flat
        prepend-inner-icon="mdi-lock-outline"
        filled
        rounded
        class="fonte-bold"
        dense
        @click:append="typePass = !typePass"
        :append-icon="typePass ? 'mdi-eye' : 'mdi-eye-off'"
        @keyup.enter="validaCampo"
        :type="typePass ? 'text' : 'password'"
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.senha"
        autocomplete="new-senha"
      >
      </v-text-field>
    </v-flex>

    <v-flex class="ma-1 mb-6" xs12 md6>
      <div class="expande-horizontal centraliza">
        <v-btn rounded dark :color="$theme.primary" @click="validaCampo">
          <span class="fonte">Entrar</span>
        </v-btn>
      </div>
    </v-flex>
    <v-flex class="ma-1 mb-6" xs12 md6>
      <div class="expande-horizontal centraliza">
        <v-btn
          small
          :color="$theme.primary"
          @click="setModalForgotPassword(true)"
          text
          class="fonte grey--text text-capitalize"
        >
          <span>
            Recuperar senha
          </span>
        </v-btn>
      </div>
    </v-flex>

    <!-- <v-flex class="ma-1 mt-6" xs12 md6>
      <div class="expande-horizontal centraliza">
        <span class="fonte">Ainda não tem uma conta?</span>
        <span
          @click="$router.push('/comprar-plano/essencial')"
          class="fonte ml-1"
          :style="`color: ${$theme.secondary}; cursor: pointer;`"
          >Assine agora</span
        >
      </div>
    </v-flex> -->
    <ForgotPass />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ForgotPass from "./ForgotPassword.vue";
export default {
  data() {
    return {
      payload: {
        username: "",
        senha: ""
      },
      typePass: false
    };
  },
  components: {
    ForgotPass
  },
  methods: {
    ...mapActions(["createSession", "setModalForgotPassword"]),
    validaCampo() {
      if (this.$refs.form.validate()) {
        this.createSession(this.payload);
      }
    }
  }
};
</script>
